.header-box {
	background-color: var(--white);
	width: 100%;
	height: auto;
	max-height: 210px;
	padding: 30px 50px 20px 50px;

	.filter-box {
		background-color: var(--azul-happy);
		height: 40px;
		width: 40px;
		border-radius: 20px;
		margin-right: 0px;
	}

	.filter-box:hover {
		opacity: 0.9;
		cursor: pointer;
	}

	.search-box {
		width: 100%;
		margin-bottom: 20px;

		.total {
			background: var(--azul-secundario);
			border-radius: 8px;
			padding: 12px 14px;
			margin-left: 15px;
			min-width: auto;
			height: 50px;
			max-height: 55px;

			h6 {
				font-family: Open Sans;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 24px;
				text-transform: uppercase;
				white-space: nowrap;
				color: var(--primary-blue-base);
			}

			p {
				font-family: Open Sans;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				color: var(--primary-blue-base);
				margin-left: 10px;
				white-space: nowrap;
			}
		}
	}

	.select-box {
		position: relative;

		.text-over-input {
			position: relative;
			top: 10px;
			left: 20px;
			background-color: var(--white);
			width: fit-content;
			padding: 0x 10px;
			text-align: center;

			small {
				color: var(--cinza-azulado-900);
			}
			small:hover {
				color: #6200ee;
			}
		}
	}
}

.header-box-cargos {
	background-color: var(--white);
	width: 100%;
	height: auto;
	max-height: 150px;
	padding: 50px;

	.search-box {
		width: 100%;
		margin-bottom: 20px;

		.total {
			background: var(--azul-secundario);
			border-radius: 8px;
			padding: 12px 14px;
			margin-left: 15px;
			min-width: auto;
			height: 50px;
			max-height: 55px;

			h6 {
				font-family: Open Sans;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 24px;
				text-transform: uppercase;
				white-space: nowrap;
				color: var(--primary-blue-base);
			}

			p {
				font-family: Open Sans;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				color: var(--primary-blue-base);
				margin-left: 10px;
				white-space: nowrap;
			}
		}
	}
}

.header-lead {
	background-color: var(--white);
	width: 100%;
	height: auto;
	max-height: 210px;
	padding: 30px 50px 20px 50px;

	.icone-back {
		font-size: 30px;
		color: var(--cinza-azulado-500);
		cursor: pointer;
	}

	.box-tag-novo {
		padding: 10px;
		border-radius: 5px;
		background-color: var(--azul-happy);

		p {
			color: var(--white);
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
		}
	}

	.box-tag-hoje {
		padding: 10px;
		border-radius: 5px;
		background-color: var(--amarelo-hoje);

		p {
			color: var(--white);
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
		}
	}

	.box-tag-futuro {
		padding: 10px;
		border-radius: 5px;
		background-color: var(--verde-futuro);

		p {
			color: var(--white);
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
		}
	}

	.box-tag-atrasado {
		padding: 10px;
		border-radius: 5px;
		background-color: var(--vermelho-atrasado);

		p {
			color: var(--white);
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
		}
	}

	h5 {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 600;
		font-size: 32px;
		line-height: 24px;
		color: var(--azul-happy);
	}
}
