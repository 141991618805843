#header {
	position: relative;
	padding: 24px 8px 24px 8px;
	top: 0px;
	left: 0px;
	background-color: var(--white);
	max-height: 100vh !important;
	z-index: 100;

	.pro-sidebar.collapsed {
		width: 80px;
		min-width: 80px;
	}

	.pro-sidebar-inner {
		background-color: var(--white-color);

		.pro-sidebar-layout {
			overflow-y: hidden;

			ul {
				padding: 0 5px;

				.pro-inner-item {
					color: var(--secondary-color);
					margin: 5px 0px;
					font-family: Open Sans;
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 24px;

					.pro-icon-wrapper {
						background-color: transparent;
						color: var(--secondary-color);
						border-radius: 3px;
						.pro-item-content {
							color: #000;
						}
					}
				}

				.item-arrow {
					.pro-inner-item {
						.pro-icon-wrapper {
							background-color: var(--primary-silver-dark);
							width: 35px;
							height: 35px;
							border-radius: 20px;
						}
					}
				}

				.active {
					background: var(--azul-happy);
					border-radius: 16px;
					color: white;
				}

				.active:hover {
					color: var(--azul-happy);
					svg {
						fill: var(--azul-happy);
					}
				}
			}

			.logotext p {
				font-size: 20px;
				padding: 0 20px;
				color: #000;
				font-weight: bold;
			}
		}
	}

	.logo {
		padding: 20px;
	}

	.pro-sidebar {
		width: 220px;
		min-width: 220px;

		.pro-menu {
			.pro-menu-item.item-avatar:hover {
				background: transparent;
				border-radius: 16px;
			}

			.pro-menu-item.item-danger:hover {
				background: rgba(207, 107, 108, 0.2);
				border-radius: 16px;

				span.pro-item-content {
					color: var(--red-color) !important;
				}
			}

			.pro-menu-item:hover {
				background: var(--azul-secundario);
				border-radius: 16px;

				.pro-inner-item {
					span.pro-item-content {
						color: var(--primary-blue-base) !important;
					}
				}
			}
		}
	}

	.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
		font-size: 12px;
	}

	.pro-menu-item.active span.pro-item-content {
		color: var(--primary-color) !important;
	}

	.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
		border-bottom: 0px solid !important;
	}

	.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
		padding: 8px 18px 8px 18px !important;
		cursor: initial;
	}

	.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
		flex-grow: 0 !important;
	}

	.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
		border-top: 0px solid !important;
	}
}

.box-avatar {
	h6 {
		font-family: Open Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: var(--primary-gray-base);
		margin-bottom: 2px;
	}

	p {
		font-family: Open Sans;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: var(--primary-blue-base);
		margin-bottom: 2px;
	}
}

.logo-grande {
	width: 150px;
	height: auto;
}

@media screen and (max-width: 767px) {
	#header {
		display: none;
	}
}
