.box-tag-status {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	width: max-content;

	background-color: var(--azul-happy);
	border-radius: 10px;
	padding: 10px;
	box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.box-tag-status p {
	color: #fff !important;
	text-align: center;
}
