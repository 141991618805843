.ck-editor__editable {
	min-height: 300px;
}

.header-panel {
	width: 100%;
	border-radius: 8px;
	background-color: var(--white);
	padding: 15px;

	.box-panel {
		padding: 10px;
		margin-right: 5px;
		white-space: nowrap;
	}

	h4 {
		font-family: 'Arial';
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 21px;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: #304ffe;
		margin-bottom: 10px !important;
	}

	h5 {
		font-family: 'Arial';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 23px;
		letter-spacing: 0.02em;
		color: var(--cinza-azulado-200);
		margin-bottom: 20px !important;
	}

	h3 {
		font-family: 'Arial';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 21px;
		letter-spacing: 0.02em;
		color: var(--cinza-azulado-700);
	}
}

.body-panel {
	margin-top: 15px;
	margin-bottom: 15px;

	.tarefa-panel {
		width: 100%;
		border-radius: 8px;
		background-color: var(--white);
		padding: 20px;
		margin-bottom: 15px;
		position: relative;

		.circle-cinza {
			background-color: var(--cinza-azulado-100);
			border-radius: 20px;
			height: 40px;
			width: 40px;
		}

		.line-cinza {
			width: 100%;
			height: 1px;
			background-color: var(--cinza-azulado-100);
		}

		.dot-absolute {
			position: absolute;
			top: 20px;
			right: 20px;
		}

		label {
			text-transform: uppercase;
		}

		h1 {
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			line-height: 24px;
			color: #000000;
			margin-bottom: 20px !important;
		}

		h2 {
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;
			color: var(--cinza-azulado-900);
			margin-bottom: 25px !important;
		}

		h3 {
			font-family: 'Arial';
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 0.02em;
			color: #607d8b;
			margin-bottom: 20px !important;
		}

		h5 {
			font-family: 'Arial';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 0.02em;
			color: #607d8b;
			margin-bottom: 25px !important;
		}

		h6 {
			font-family: 'Arial';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 21px;
			letter-spacing: 0.02em;
			text-decoration-line: underline;
			color: #2979ff;
			margin-bottom: 25px !important;
		}

		p {
			font-family: 'Arial';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 0.02em;
			color: #607d8b;
		}

		.box-documentos {
			width: 100%;
			padding: 30px;
			background-color: #eceff1;

			.box-white {
				padding: 10px;
				background-color: var(--white);
				border-radius: 6px;
				height: 50px;
				flex: 1;

				p {
					font-family: 'Arial';
					font-style: normal;
					font-weight: 400;
					font-size: 16px !important;
					line-height: 21px;
					color: var(--border-cinza) !important;
				}
			}
		}
	}

	table {
		.header-row {
			text-align: left;
			color: var(--cinza-azulado-500);
			padding: 20px 0px;
			border-bottom: 1px solid var(--cinza-azulado-200);
		}

		tr {
			border-bottom: 1px solid var(--cinza-azulado-200);
		}

		td {
			min-height: 70px;
			height: 70px;
			vertical-align: middle;

			p {
				text-align: left;
				color: var(--cinza-azulado-500) !important;
			}
		}
	}

	.side-panel {
		width: 100%;
		border-radius: 8px;
		background-color: var(--white);
		padding: 15px;
	}
}

#sem-margin {
	margin-bottom: 0px !important;
}

.input-box {
	border: 0px solid #ffffff;
}

.text-area-p {
	font-family: 'Arial';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	color: #6c757d;
}

#tab-comunicacao {
	background-color: #ffffff;
	border-radius: 8px;
	padding: 0px;

	h1 {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		line-height: 24px;
		color: #000000;
	}

	.box-inputs {
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid var(--cinza-azulado-100);
	}

	label {
		font-family: 'Arial';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 23px;
		letter-spacing: 0.02em;
		color: var(--cinza-azulado-200);
	}

	h6 {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;
		color: var(--cinza-azulado-500);
	}

	.box-input-email {
		height: 60px;
	}

	h3 {
		font-family: 'Arial';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 23px;
		letter-spacing: 0.02em;
		color: #607d8b;
	}

	h5 {
		font-family: 'Arial';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 23px;
		letter-spacing: 0.02em;
		color: #607d8b;
	}

	.border-box {
		padding: 10px;
		border: 1px solid var(--cinza-azulado-100);
		border-radius: 8px;
	}

	.box-timeline {
		.box-filtros {
			border-bottom: 1px solid var(--cinza-azulado-100);
			border-top: 1px solid var(--cinza-azulado-100);
			padding: 20px 0px;

			.circle-azul {
				width: 50px;
				height: 50px;
				padding: 10px;
				border: 2px solid var(--azul-happy);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.box-timelines {
			.box-title-timeline {
				background: #ebeffc;
				border-radius: 2px;
				padding: 10px;
				margin-left: 70px;

				h6 {
					color: var(--azul-happy);
					font-family: 'Arial';
					font-style: normal;
					font-weight: 700;
					font-size: 16px;
					line-height: 21px;
					letter-spacing: 0.02em;
				}
			}

			.box-azul-timeline {
				background: #ebeffc;
				border-radius: 8px;
				padding: 20px;
			}
		}
	}
}

label.radio-tarefa {
	font-family: 'Arial' !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
	line-height: 21px !important;
	color: #455a64 !important;

	.ant-radio-checked .ant-radio-inner {
		border-color: #1677ff !important;
	}

	.ant-radio-inner::after {
		background-color: var(--white) !important;
	}
}

.ant-radio-group {
	display: flex !important;
	flex-wrap: wrap !important;
	gap: 20px !important;
}

.text-title-timeline {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.02em;
	color: #607d8b;
}

.text-lead-timeline {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.02em;
	color: #607d8b;
}

#dados-lead {
	.input-form {
		display: flex;
		flex: 1;
	}

	.box-white {
		padding: 10px;
		background-color: var(--white);
		border-radius: 6px;
		border: 1px solid var(--border-cinza) !important;
		height: 50px;
		flex: 1;

		p {
			font-family: 'Arial';
			font-style: normal;
			font-weight: 400;
			font-size: 16px !important;
			line-height: 21px;
			color: var(--border-cinza) !important;
		}
	}
}

.shadow-lead {
	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.border-input-lead {
	border: 1px solid var(--border-cinza) !important;
}